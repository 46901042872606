import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    auth: {
      loggedIn: false,
      user: {},
      blogs: []
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  },
  pageTitle: false
})
